import React from 'react'
import {graphql, Link} from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import Share from '../components/share'
import PortableText from '../components/portableText'
import {getFullUrl, getPostUrl} from '../lib/helpers'
import Sidebar from "../components/sidebar";
import Article from "../components/article";

export const query = graphql`
query PageQuery($id: String!) {
    page: sanityPage(id: {eq: $id}) {
        id
        mainImage {
            ...MainImage
        }
        title
        slug {
          current
        }
        _rawExcerpt(resolveReferences: {maxDepth: 5})
        _rawBody(resolveReferences: {maxDepth: 5})
    }
}
`

class PagePage extends React.Component {
    constructor(props) {
        super(props);
        const {data, errors} = props
        this.state = {
            page: (data || {}).page,
        }
    }

    render() {
        return (
            <>
                <SEO
                    title={this.state.page.title}
                    image={this.state.page.mainImage ? this.state.page.mainImage : null}
                />

                <Article
                    title={this.state.page.title}
                    excerpt={(<PortableText blocks={this.state.page._rawExcerpt}/>)}
                    image={this.state.page.mainImage ? (<Img fluid={this.state.page.mainImage.asset.fluid}/>) : ''}
                    sidebar={(
                        <Sidebar/>
                    )}>
                    <div className="body-text">
                        {this.state.page._rawBody &&
                        <PortableText blocks={this.state.page._rawBody}/>}
                    </div>

                    <Share
                        url={getFullUrl(getPostUrl(this.state.page.publishedAt, this.state.page.slug))}
                        title={this.state.page.title}/>
                </Article>
            </>
        )
    }
}

export default PagePage
