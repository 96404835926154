import React from 'react'
import {getPostUrl} from "../lib/helpers";
import {format} from "date-fns";
import {graphql, Link, StaticQuery} from 'gatsby'

const postsQuery = graphql`
query DefaultPostsQuery {
    posts: allSanityPost(
        limit: 3
        sort: { fields: [publishedAt], order: DESC }
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
        edges {
            node {
                ...PostPreview
            }
        }
    }
}
`

const Sidebar = ({posts = {}, before = null, after = null}) => (
    <StaticQuery
        query={postsQuery}
        render={data => {
            return (
                <div className='mt-8 md:mt-0'>
                    {before}

                    <div className="">
                        <h4 className='inline-flex uppercase text-brand-red text-lg font-semibold font-heading rounded-t-3xl bg-white px-8 pt-4'>
                            Siste fra bloggen
                        </h4>
                    </div>
                    <div className='p-8 bg-white rounded-tr-3xl rounded-bl-3xl rounded-br-3xl relative'>
                        <div className="-mx-4">
                            {data.posts.edges.map(({node, index}) => (
                                <Link key={`posts-${node.slug.current}`} to={getPostUrl(node.publishedAt, node.slug)}
                                      className='flex border-b p-4 items-end hover:bg-gray-100 group'>
                            <span className='flex flex-col flex-1'>
                                <span
                                    className='text-brand-grey-lighter mb-2'>{format(node.publishedAt, 'DD/MM/YYYY')}</span>
                                <span className='text-base'>{node.title}</span>
                            </span>
                                    <span>
                                <span
                                    className='w-8 h-8 flex items-center justify-center rounded-full bg-brand-grey-lighter group-hover:bg-brand-red transition duration-75 ease'>
                                    <span
                                        className="block border-b-2 border-r-2 border-white h-3 w-3 transform -rotate-45 -ml-1"/>
                                </span>
                            </span>
                                </Link>
                            ))}
                        </div>

                    </div>

                    {after}
                </div>
            )
        }}/>
)

export default Sidebar;